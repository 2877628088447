











import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

import SystemMessageCard from '@/components/cards/SystemMessageCard.vue'

@Component({
  components: {
    SystemMessageCard,
  },
})
export default class Failed extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Платеж не был совершен',
    }
  }
}
